import { useState, useEffect } from "react";
import { Row, Col, Form, Popconfirm } from "antd";
import dayjs from "dayjs";

import { api } from "../../../../../api";
import { DatePicker, Input, Select } from "../../../../../components/form-fields";
import {
	IDegreeData,
	IUniversityData,
	ISpecialisationData,
} from "../../../../../utils/common-interfaces";
import AddFieldIcon from "../../../../../assets/images/add-field.svg";
import RemoveFieldIcon from "../../../../../assets/images/remove-field-icon.svg";

import styles from "./education.module.scss";

interface IProps {
	fromPublicPage?: boolean;
	initialFieldsCount?: number;
	cgpaLimit?: number;
}

const CandidateEducationForm = ({
	fromPublicPage,
	initialFieldsCount = 0,
	cgpaLimit = 10,
}: IProps) => {
	const form = Form.useFormInstance();
	const [universitiesData, setUniversitiesData] = useState<Array<IUniversityData>>([]);
	const [degreeData, setDegreeData] = useState<Array<Array<IDegreeData>>>([]);
	const [specialisationData, setSpecialisationData] = useState<Array<Array<ISpecialisationData>>>(
		[]
	);

	useEffect(() => {
		getUniversities();
	}, []);

	useEffect(() => {
		if (universitiesData?.length) {
			const educationVals = form.getFieldValue("education");
			if (educationVals?.length) {
				educationVals.forEach((education: any, index: number) => {
					if (education) {
						getDegree(education.university_id, index);
						getSpecialisations(education.university_id, education.degree_id, index);
					}
				});
			}
		}
	}, [universitiesData]);

	useEffect(() => {
		const educationFields = form.getFieldValue("education") || [];
		if (educationFields.length === 0 && initialFieldsCount > 0) {
			const fieldsToAdd = Array(initialFieldsCount)
				.fill(null)
				.map(() => ({}));
			form.setFieldValue("education", fieldsToAdd);
		}
	}, [initialFieldsCount, form]);

	const getUniversities = () => {
		api
			.get({
				path: fromPublicPage ? `/public/universities` : `/universities`,
				service: "job",
			})
			.then((response) => setUniversitiesData(response.data?.data || []));
	};

	const getDegree = (universityId: string, index: number) => {
		const deg: any = universitiesData.find((el: any) => el.id === universityId);
		setDegreeData((prev) => {
			prev[index] = deg?.degree?.map((el: any) => el) || [];
			return [...prev];
		});
	};

	const getSpecialisations = (universityId: string, degreeId: string, index: number) => {
		const deg: any = universitiesData.find((el: any) => el.id === universityId);
		setSpecialisationData((prev) => {
			prev[index] = deg?.degree?.find((el: any) => el.id === degreeId)?.specializations || [];
			return [...prev];
		});
	};

	const handleUniversityChange = (val: string, options: any, index: number) => {
		getDegree(val, index);
		form.setFieldValue([`education`, `${index}`, `degree_id`], undefined);
		form.setFieldValue([`education`, `${index}`, `specialisation_id`], undefined);
	};

	const handleDegreeChange = (val: string, options: any, index: number) => {
		const getUniversitiesValue = form.getFieldValue([`education`, `${index}`, `university_id`]);
		getSpecialisations(getUniversitiesValue, val, index);
		form.setFieldValue([`education`, `${index}`, `specialisation_id`], undefined);
	};

	const validateCGPA = (value: number) => {
		if (value && value > cgpaLimit) {
			return Promise.reject(new Error(`CGPA cannot be more than ${cgpaLimit}`));
		}
		if (value && value < 0) {
			return Promise.reject(new Error(`CGPA cannot be less than 0`));
		}
		return Promise.resolve();
	};

	return (
		<div className={styles["add-team-member"]}>
			<Row className={styles["detail-row"]}>
				<Col sm={24} md={24}>
					<Form.List name="education">
						{(fields, { add, remove }) => {
							return (
								<>
									{fields.map((field, index) => (
										<Row gutter={20} key={field.key}>
											<Col sm={24} md={12}>
												<Form.Item
													label="University/College"
													name={[field.name, "university_id"]}
													rules={[
														{
															required: true,
															message: "Please enter university",
														},
													]}
												>
													<Select
														options={universitiesData?.map((el) => ({
															value: el.id,
															label: el.name,
														}))}
														onChange={(val, options) => handleUniversityChange(val, options, index)}
														placeholder="Select university"
														optionFilterProp="label"
													/>
												</Form.Item>
											</Col>
											<Col sm={24} md={12}>
												<Form.Item
													label="Degree"
													name={[field.name, "degree_id"]}
													rules={[
														{
															required: true,
															message: "Please enter degree",
														},
													]}
												>
													{/* <Input placeholder="Enter Degree Name" /> */}
													<Select
														disabled={
															!form.getFieldValue([`education`, `${field.name}`, `university_id`])
														}
														options={degreeData?.[index]?.map((el: any) => ({
															value: el.id,
															label: el.name,
														}))}
														onChange={(val, options) => handleDegreeChange(val, options, index)}
														placeholder="Select degree"
														optionFilterProp="label"
													/>
												</Form.Item>
											</Col>
											<Col sm={24} md={12}>
												<Form.Item
													label="Specialization"
													name={[field.name, "specialisation_id"]}
													rules={[
														{
															required: true,
															message: "Please enter specialization!",
														},
													]}
												>
													<Select
														disabled={
															!form.getFieldValue([
																`education`,
																`${field.name}`, // this was key before. changed it to check hte bug
																`degree_id`,
															])
														}
														options={specialisationData?.[index]?.map((el: any) => ({
															value: el.id,
															label: el.name,
														}))}
														placeholder="Select specialization"
														optionFilterProp="label"
													/>
												</Form.Item>
											</Col>
											<Col sm={24} md={12}>
												<Form.Item
													label="CGPA"
													name={[field.name, "cgpa"]}
													rules={[
														{
															required: true,
															message: "Please enter CGPA",
														},
														{
															validator: (_, value) =>
																cgpaLimit ? validateCGPA(value) : Promise.resolve(),
														},
													]}
												>
													<Input
														type="number"
														placeholder="Enter CGPA"
														onChange={(event) => {
															const value = parseFloat(event.target.value);
															const roundedValue = Math.round(value * 100) / 100;
															form.setFieldsValue({
                                education: {
                                    [field.name]: {
                                        cgpa: roundedValue,
                                    },
                                },
                            });
														}}
													/>
												</Form.Item>
											</Col>
											<Col sm={24} md={12}>
												<Form.Item
													label="Year of joining"
													name={[field.name, "joining_year"]}
													rules={[
														{
															required: true,
															message: "Please enter joining year",
														},
													]}
												>
													<DatePicker
														picker="year"
														disabledDate={(current) => current && current > dayjs().endOf("year")}
													/>
												</Form.Item>
											</Col>
											<Col sm={24} md={12}>
												<Form.Item
													label="Year of completion"
													name={[field.name, "completion_year"]}
													dependencies={[["education", field.name, "joining_year"]]}
													rules={[
														{
															required: true,
															message: "Please enter completion year",
														},
														({ getFieldValue }) => ({
															validator(_, value) {
																const joiningYear = getFieldValue([
																	"education",
																	field.name,
																	"joining_year",
																]);

																if (
																	value &&
																	joiningYear &&
																	value.format("YYYY") < joiningYear.format("YYYY")
																) {
																	return Promise.reject(
																		new Error("Completion year cannot be less than joining year!")
																	);
																}

																return Promise.resolve();
															},
														}),
													]}
												>
													<DatePicker picker="year" />
												</Form.Item>
											</Col>
											<Col sm={24} md={12} className={styles["remove-field-col"]}>
												<Form.Item>
													<div className={styles["remove-field"]}>
														<img src={RemoveFieldIcon} alt="" />
														<Popconfirm
															title="Are you sure you want to remove degree?"
															onConfirm={() => remove(field.name)}
															overlayClassName={styles["popconfirm-btns"]}
															okText="Confirm"
															cancelText="Cancel"
															placement="topRight"
														>
															<span className={styles["remove-text"]}>Remove Degree</span>
														</Popconfirm>
													</div>
												</Form.Item>
											</Col>
										</Row>
									))}
									<Form.Item>
										<div className={styles["add-education-field"]} onClick={() => add()}>
											<img src={AddFieldIcon} alt="" />
											<span className={styles["add-text"]}>
												{`Add ${fields.length > 0 ? "another" : ""} degree`}
											</span>
										</div>
									</Form.Item>
								</>
							);
						}}
					</Form.List>
				</Col>
			</Row>
		</div>
	);
};

export default CandidateEducationForm;
