import { useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Col, Form, FormInstance, Row } from "antd";
import dayjs from "dayjs";

import { gender, maritalStatus } from "../../../../constants";
import {
	DatePicker,
	Input,
	MobileInput,
	Select,
	TextArea,
} from "../../../../components/form-fields";
import Heading from "../../../../components/heading";
import { useCountryList, useCurrentUser } from "../../../../hooks";
import ProfileImage from "../../../../components/profile-image";
import { validateTextLimit } from "../../../../utils/function-utils";
interface IProps {
	form: FormInstance<any>;
}

const BasicInfo = (props: IProps) => {
	const { form } = props;
	const { user } = useCurrentUser();
	const [countries, setCountries] = useState<string[]>();
	const [states, setStates] = useState<Array<string>>([]);
	const [cityData, setCityData] = useState<Array<string>>([]);
	const { getCity, getCountries, getStates } = useCountryList();

	useEffect(() => {
		const country = form.getFieldValue(["address", "personal", "country"]);
		const state = form.getFieldValue(["address", "personal", "state"]);
		handleCountryChange(country, true);
		handleStateChange(state, true);
	}, []);

	useEffect(() => {
		const getCountriesList = getCountries ? getCountries() : [];
		setCountries(getCountriesList);
	}, []);

	const handleCountryChange = (val: string, trigger?: boolean) => {
		if (getStates) {
			const getStatesList = getStates(val);
			setStates(getStatesList);
		}

		if (!trigger) {
			form.setFieldValue(["address", "personal", "state"], undefined);
			form.setFieldValue(["address", "personal", "city"], undefined);
		}
		setCityData([]);
	};

	const handleStateChange = (val: string, trigger?: boolean) => {
		const getCounty = form.getFieldValue(["address", "personal", "country"]);
		if (getCity) {
			const getCityList = getCity(getCounty, val);
			setCityData(getCityList);
		}

		if (!trigger) {
			form.setFieldValue(["address", "personal", "city"], undefined);
		}
	};

	const handleProfilePicSuccess = (url: string | null) => {
		if (url) form.setFieldValue("profile_pic", url);
	};

	return (
		<>
			<Form.Item name="profile_pic">
				<ProfileImage
					editable
					teamMemberId={user?.team_member_id}
					onUploadSuccess={handleProfilePicSuccess}
				/>
			</Form.Item>
			<Heading title="Basic Details" />
			<Row gutter={20}>
				<Col sm={24} md={12}>
					<Form.Item
						label="First name"
						name="first_name"
						rules={[
							{
								required: true,
								message: "Please input first name!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Enter First Name" />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Last name"
						name="last_name"
						rules={[
							{
								required: true,
								message: "Please input last name!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Enter Last Name" />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Date of Birth"
						name="date_of_birth"
						rules={[
							{
								required: true,
								message: "Please input date of birth!",
							},
						]}
					>
						<DatePicker
							placeholder="Select Date of Birth"
							format={"DD-MM-YYYY"}
							disabledDate={(current) => {
								const today = dayjs();
								const age = today.diff(current, "year");
								return age < 18;
							}}
						/>
					</Form.Item>
				</Col>
				<Col sm={24} md={12}>
					<Form.Item
						label="Gender"
						name="gender"
						rules={[
							{
								required: true,
								message: "Please select your gender!",
							},
						]}
					>
						<Select placeholder="Select Gender" options={gender} />
					</Form.Item>
				</Col>
				<Col sm={24} md={12}>
					<Form.Item
						label="Marital Status"
						name="marital_status"
						rules={[
							{
								required: true,
								message: "Please select marital status!",
							},
						]}
					>
						<Select
							placeholder="Select marital status"
							options={maritalStatus.map((el) => ({ value: el.label, label: el.label }))}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Heading title="Contact Details" />
			<Row gutter={20}>
				<Col sm={24} md={12}>
					<Form.Item
						label="Personal Email Address"
						name="email"
						rules={[
							{
								required: true,
								message: "Please input email!",
								whitespace: true,
							},
							{ type: "email", message: "Please input valid email!" },
						]}
					>
						<Input placeholder="Enter Email Address" />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Phone Number"
						name="mobile_no"
						rules={[
							{
								required: true,
								message: "Please input mobile number!",
							},
							() => ({
								validator(_, value) {
									if (
										value &&
										!isValidPhoneNumber(`${value}`?.startsWith("+") ? value : `+${value}`)
									) {
										return Promise.reject("Please input valid mobile number!");
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<MobileInput />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Address"
						name={["address", "personal", "address_line"]}
						rules={[
							{
								required: true,
								message: "Please input address!",
								whitespace: true,
							},
							{
								validator: (_, value) => validateTextLimit(value, 500),
							},
						]}
					>
						<TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Country"
						name={["address", "personal", "country"]}
						rules={[
							{
								required: true,
								message: "Please select country!",
								whitespace: true,
							},
						]}
					>
						<Select
							options={countries?.map((el) => ({
								value: el,
								label: el,
							}))}
							onChange={(val) => handleCountryChange(val)}
							placeholder="Select country"
						/>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="State"
						name={["address", "personal", "state"]}
						rules={[
							{
								required: states.length !== 0,
								message: "Please select state!",
								whitespace: true,
							},
						]}
					>
						<Select
							options={states?.map((el) => ({
								value: el,
								label: el,
							}))}
							onChange={(val) => handleStateChange(val)}
							placeholder="Select state"
						/>
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="City"
						name={["address", "personal", "city"]}
						rules={[
							{
								required: cityData.length !== 0,
								message: "Please select city!",
								whitespace: true,
							},
						]}
					>
						<Select
							options={cityData?.map((el) => ({
								value: el,
								label: el,
							}))}
							placeholder="Select city"
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

export default BasicInfo;
