import { useCallback, useEffect, useState } from "react";
import { Dropdown, Pagination, Tooltip } from "antd";

import SearchIcon from "../../assets/images/search.svg";
import ListIcon from "../../assets/images/list-icon.svg";
import GridIcon from "../../assets/images/grid-icon.svg";
import OrgIcon from "../../assets/images/org-icon.svg";
import CancelIcon from "../../assets/images/cross-icon-black.svg";
import CustomButton from "../../components/button";
import SubMenu from "../../assets/images/sub-menu.svg";
import { IPagination } from "../table";
import { debounceMethod } from "../../utils/function-utils";

import styles from "./headerTwo.module.scss";
import { Input } from "../../components/form-fields";

export type HeaderViewTypes = "list" | "grid" | "tree";

interface IPossibleViewOptions {
	key: HeaderViewTypes;
	label: string;
	icon: string;
}

type CommonProps = {
	leftSideContent?: React.ReactNode;
	searchedText?: string;
	placeholder?: string;
	setSearchedText?: (searchedText: string) => void;
	menuItems?: {
		key: string;
		label: string | React.ReactNode;
		onClick?: (props: {
			key: string;
			domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
		}) => void;
	}[];
	customRightButton?: {
		label: string;
		onClick: () => void;
		icon: string;
	};
	pagination?: IPagination;
	setPagination?: (newPagination: IPagination) => void;
	showPagination?: boolean;
	rightSideContent?: React.ReactNode;
	viewType?: never;
	setViewType?: never;
	viewOptions?: never;
	wrapperClassName?: string;
	wrapperStyle?: React.CSSProperties;
	leftSideContentWrapperClass?: string;
	rightSideContentWrapperClass?: string;
};

type ViewProps = {
	viewType: HeaderViewTypes;
	setViewType: (viewType: HeaderViewTypes) => void;
	viewOptions: HeaderViewTypes[];
};

type CombinedViewProps = ViewProps & Omit<CommonProps, "viewType" | "setViewType" | "viewOptions">;

const HeaderTwo = (props: CommonProps | CombinedViewProps) => {
	const {
		leftSideContent,
		viewType,
		setViewType,
		viewOptions,
		pagination,
		setPagination,
		searchedText,
		setSearchedText,
		menuItems,
		rightSideContent,
		placeholder,
		customRightButton,
		wrapperClassName,
		wrapperStyle,
		showPagination = true,
		leftSideContentWrapperClass,
		rightSideContentWrapperClass,
	} = props;
	const [search, setSearch] = useState<string>(searchedText || "");
	const [expandSearch, setExpandSearch] = useState<boolean>(false);

	let possibleViewOptions: IPossibleViewOptions[] = [
		{
			key: "list",
			label: "List View",
			icon: ListIcon,
		},
		{
			key: "grid",
			label: "Grid View",
			icon: GridIcon,
		},
		{
			key: "tree",
			label: "Tree View",
			icon: OrgIcon,
		},
	];

	if (viewOptions) {
		possibleViewOptions = possibleViewOptions.filter((el) => viewOptions.includes(el.key));
	}

	useEffect(() => {
		setSearch(search);
	}, [searchedText]);

	const pageOptions = ["10", "15", "30", "50", "100"];

	const debouncedChange = useCallback(debounceMethod(setSearchedText), []);

	const selectedViewType = possibleViewOptions.find((el) => el.key === viewType);

	return (
		<div
			className={`${styles["header-two-wrapper"]} ${wrapperClassName || ""} `}
			style={{ ...(wrapperStyle ?? {}) }}
		>
			{leftSideContent && (
				<div className={leftSideContentWrapperClass ?? ""}>{leftSideContent}</div>
			)}
			<div
				className={`${styles["right-content"]} ${pagination && viewType !== "list" ? styles["right-sided-pagination"] : ""}`}
			>
				{rightSideContent && (
					<div className={rightSideContentWrapperClass ?? ""}>{rightSideContent}</div>
				)}
				<div className={styles["buttons"]}>
					{setSearchedText && viewType != "tree" && (
						<div
							className={`${styles["search-wrapper"]} ${
								expandSearch ? styles["search-wrapper-expanded"] : ""
							}`}
						>
							<Input
								id="table-search-input"
								type="search"
								className={styles["input"]}
								placeholder={placeholder ?? "Search..."}
								value={search}
								onChange={(e) => {
									setSearch(e.target.value);
									debouncedChange(e.target.value);
								}}
							/>
							<label
								htmlFor="table-search-input"
								className={styles["search-icon"]}
								onClick={() => setExpandSearch(true)}
							>
								<img src={SearchIcon} alt="search" />
							</label>

							<img
								src={CancelIcon}
								className={styles["cancel-icon"]}
								onClick={() => {
									setSearch("");
									setSearchedText("");
									setExpandSearch(false);
								}}
							/>
						</div>
					)}

					{viewType && setViewType && selectedViewType && (
						<Dropdown
							trigger={["click"]}
							menu={{
								items: possibleViewOptions.map((item) => ({
									key: item.key,
									label: item.label,
									icon: <img alt={item.label} src={item.icon} width={12} height={12} />,
								})),
								selectedKeys: [selectedViewType.key],
								onClick: ({ key }) => setViewType(key as HeaderViewTypes),
							}}
						>
							<img
								src={selectedViewType.icon}
								alt={selectedViewType.label}
								id="view-button"
								className="cursor-pointer"
							/>
						</Dropdown>
					)}

					{menuItems && menuItems.length > 0 && (
						<Dropdown menu={{ items: menuItems }} overlayClassName={styles["dropdown-class"]}>
							<div className={styles["sub-menu-icon"]}>
								<img src={SubMenu} alt="submenu" />
							</div>
						</Dropdown>
					)}

					{customRightButton && (
						<Tooltip title={customRightButton.label} placement="bottomRight">
							<div className={styles["sub-menu-icon"]} onClick={customRightButton.onClick}>
								<img src={customRightButton.icon} alt="btn" />
							</div>
						</Tooltip>
					)}

					{pagination && setPagination && viewType != "tree" && (
						<Pagination
							current={pagination.current}
							pageSize={pagination.pageSize}
							className={showPagination ? styles["right-pagination"] : ""}
							total={pagination.total}
							onChange={(page, pageSize) =>
								setPagination({
									...pagination,
									current: page,
									pageSize: pageSize,
								})
							}
							itemRender={(_, type, element) => {
								if (
									(type === "prev" ||
										type === "next" ||
										type === "jump-next" ||
										type === "jump-prev") &&
									!showPagination
								) {
									return null;
								}
								return element;
							}}
							showSizeChanger={false}
							showTotal={(total, range) => (
								<Dropdown
									menu={{
										items: pageOptions.map((el) => ({ key: el, label: el })),
										selectedKeys: [pagination.pageSize.toString()],
										onClick: ({ key }) =>
											setPagination({
												...pagination,
												current: 1,
												pageSize: Number(key),
											}),
									}}
								>
									<div>{`${range[0]}-${range[1]} of ${total}`}</div>
								</Dropdown>
							)}
							showLessItems={true}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default HeaderTwo;
